<template>
  <div class="product">
    <productDetailTop @onLineTalk="onLineTalk" :productInfo="productInfo" :visitOpt="visitOpt" v-if="productInfo"></productDetailTop>
    <!--展品详情-->
    <div class="g-layout" v-if="productInfo">
      <div class="g-layout-r" style="margin-left: 0; margin-right: 10px">
        <div class="m-card3">
          <div class="mc-top">
            <div class="mc-top-title">
              <span class="mc-top-text mc-top-txt s-bold">Introduction</span>
            </div>
          </div>
          <div class="product-describe" v-if="introduction">
            <div v-for="(item, index1) in introduction" :key="index1">
              <!-- 文本 -->
              <p
                class="int-text"
                v-if="item.text"
                :style="{
                  'font-size': item.text.fontSize,
                  color: item.text.color,
                  'text-align': item.text.position,
                }"
                v-html="$service.replaceSpeace(item.text.content)"
              ></p>
              <!-- 图片 -->
              <div class="int-img" v-if="item.picture && item.picture.type == 1">
                <div
                  :style="{
                    'padding-left': item.picture.padding + 'px',
                    'padding-right': item.picture.padding + 'px',
                  }"
                >
                  <div v-for="(itm, idx) in item.picture.list" :key="idx" :style="{ 'margin-bottom': item.picture.spacing + 'px' }">
                    <img :src="itm.url" preview="1" :large="itm.url" style="width: 100%; display: block" />
                  </div>
                </div>
              </div>
              <!-- 轮播图片 -->
              <div v-if="item.picture && item.picture.type == 2">
                <Carousel loop style="width: 700px">
                  <CarouselItem v-for="(itm, idx) in item.picture.list" :key="idx">
                    <div class="demo-carousel">
                      <img :src="itm.url" />
                    </div>
                  </CarouselItem>
                </Carousel>
              </div>
              <!-- 视频 -->
              <div class="int-video" style="position: relative" v-if="item.video">
                <videoPlayer :url="item.video.link" :cover="item.video.cover" :time="item.video.Time"></videoPlayer>
              </div>
            </div>
          </div>
        </div>
        <evaluate v-if="productInfo.evaluateCount > 0" :pro="true" :productId="productId" :evalTags="productInfo.evaluateTags" :evalCount="productInfo.evaluateCount"></evaluate>
      </div>
      <div class="g-layout-l">
        <div class="m-card3" v-if="List && List.length">
          <div class="mc-top">
            <div class="mc-top-title">
              <span class="mc-top-text mc-top-txt s-bold">{{ $t("lang.HotProduct2") }}</span>
            </div>
          </div>
          <div class="m-list2">
            <Row>
              <Col span="24" v-for="(item, index) in List.slice(0, 3)" :key="'m-list2-1' + index" class="m-list2-box">
                <div class="ml2-item">
                  <div class="ml2-item-box" @click="goProduct(item.id)">
                    <template v-if="item.video && JSON.parse(item.video).cover && JSON.parse(item.video).cover != 1">
                      <img :src="JSON.parse(item.video).cover" v-if="JSON.parse(item.video).cover" alt />
                      <img v-else src="@/assets/img/empty.png" />
                    </template>
                    <template v-else>
                      <img :src="item.banner[0]" v-if="item.banner[0]" style="height: 100%" />
                      <img v-else src="@/assets/img/empty.png" />
                    </template>
                    <div class="play_video" v-if="item.video && JSON.parse(item.video).url">
                      <i class="iconfont icon-shipinmianxing"></i>
                    </div>
                    <div class="rebang" v-if="item.isPopularize">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center; 
                        "
                      >
                        <i class="iconfont icon-fire" style="font-size: 14px; margin-right: 2px"></i>
                        hot
                      </div>
                    </div>
                    <div class="qj_div" v-if="item.panoramaUrl">
                      <i class="iconfont icon-yuanxitubiao-"></i>
                    </div>
                    <div class="is_threeD" v-if="item.view3DUrl">
                      <i
                        class="iconfont icon-d"
                        style="
                          font-size: 12px;
                          margin-left: 6px;
                          margin-right: 4px;
                          color: #ff5c00;
                        "
                      ></i>
                      <span
                        style="
                          font-size: 12px;
                          color: #ff5c00;
                          display: inline-block;
                        "
                        >3D</span
                      >
                    </div>
                  </div>
                </div>
                <div class="ml2-item-info">
                  <p>{{ item.name }}</p>
                  <p style="color: #999" v-if="item.selling">
                    {{ item.selling }}
                  </p>
                  <p style="margin-top: 14px" v-else></p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    <chatTalkUser :exhibitorId="exhibitorId" :isAlone="true" v-if="talkShow"></chatTalkUser>
  </div>
</template>

<script>
import productDetailTop from "@components/productDetail-top/index.vue";
import evaluate from "@components/evaluate/index.vue";
import { mapActions, mapGetters } from "vuex";
import videoPlayer from "@components/videoPlayer/videoPlayer.vue";
import chatTalkUser from "@components/chat-talk-user/index.vue";
export default {
  name: "index",
  data() {
    return {
      productInfo: "",
      visitOpt: {
        //访问信息
        finished: false,
        page: 1,
        size: 20,
        totalCount: 0,
        items: [],
      },
      pictureList: [],
      introduction: "",
      pageIndex: 1,
      pageSize: 10,
      proCategory: [],
      productId: "",
      exhibitorId: "",
      List: [], //热门产品
      shareBanner: [],
      talkShow:false,
    };
  },
  components: {
    productDetailTop,
    evaluate,
    videoPlayer,
    chatTalkUser
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      userId: "getUserId",
      userInfo: "getUser",
    }),
    memberId() {
      return "c13e1521-7258-4e38-8294-0290a5dde675";
    },
    isRegOk () {
      return (
        this.userInfo &&
        this.userInfo.inMember &&
        this.userInfo.inMember.userRole &&
        this.userInfo.inMember.userRole != "VISITOR" &&
        this.userInfo.inMember.userRole != "Visitor"
      );
    },
    isServer(){
      let ser=false;
      if(this.userInfo&&this.userInfo.inMember&&this.userInfo.inMember.companyUsers&&this.userInfo.inMember.companyUsers.id){
        ser=true;
      }
      return ser
    },
  },
  created() {
    this.productId = this.$route.query.productId; 
    this.init();
    this.addView();
  },
  mounted(){
    this.$Bus.$off('talkAloneClose')
    this.$Bus.$on('talkAloneClose',()=>{
      this.talkShow=false;
    })
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      linkToProductDetais: "linkTo/linkToProductDetais",
      getShareConfig: "share/getShareConfig",
      addAllView: "addAllView",
      addAppointmentNumber:'exhibitor/addAppointmentNumber'
    }),
    addView() {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: "product",
        targetId: this.productId,
      };
      if (this.userInfo && this.userInfo.userId) {
        opt.userId = this.userInfo.userId;
      }
      this.addAllView(opt);
    },
    async setShare() {
      let data = await this.getShareConfig({ id: this.exhibitionId, name: "share.product.config" });
      let share = {
        url: location.href,
        title: this.productInfo.name || "展品详情",
        desc: "",
        summary: "",
        pics: this.shareBanner && this.shareBanner.length ? this.shareBanner[0] : "",
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          dataJson.content = dataJson.content.replace(/【展品】/g, this.productInfo.name);
          dataJson.content = dataJson.content.replace(/【公司】/g, this.productInfo.exhibitor.company.name);
          dataJson.content = dataJson.content.replace(/【展位号】/g, this.productInfo.exhibitor.boothInfo);
          dataJson.content = dataJson.content.replace(/【卖点】/g, this.productInfo.selling);
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          dataJson.des = dataJson.des.replace(/【展品】/g, this.productInfo.name);
          dataJson.des = dataJson.des.replace(/【公司】/g, this.productInfo.exhibitor.company.name);
          dataJson.des = dataJson.des.replace(/【展位号】/g, this.productInfo.exhibitor.boothInfo);
          dataJson.des = dataJson.des.replace(/【卖点】/g, this.productInfo.selling);
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    async init() {
      let query = `
        {
          productQuery{
            get(id:"${this.productId}")
              {
                compId
                banner
                category
                categoryEn
                introduction
                name
                selling
                tag
                userCount
                evaluateCount
                advisoryCount
                viewCount
                compId
                video
                likeCount
                followCount
                view3DUrl
                panoramaUrl
                isPopularize
                isFollow(userId:"${this.userId}")
                isLike(userId:"${this.userId}")
                id
                evaluateTags{ #评价标签
                      evaluateCount #评价数
                      name
                }
                exhibitor{              #参展信息
                  customerServiceIsAppointmentNumber
                  activityLiving{
                    id
                    acvitityType
                    hasLive
                    liveAuditState
                  }
                  businesses(num:3){
                    isSample
                  }
                  id
                  category
                  categoryEn
                  talkCount
                  activityCount
                  continuouNumber
                  boothInfo
                  products{           #展商展品
                    name
                    selling
                    category
                    categoryEn
                  }
                  proCount
                  productNumber
                  activityNumber
                  company{            #公司信息
                    name
                    nameEn
                    logo
                    regionEn
                    id
                  }
                  exhibition{
                    logo
                  }
                }
              }
            }
        }`;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
        // ...EqualContent,
      };
      let order = [{ N: "updateAt", v: 0 }];
      let opt = {
        query: query,
        variables: {
          query: {
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      this.productInfo = JSON.parse(JSON.stringify(data.data.productQuery.get));
      this.exhibitorId = this.productInfo.exhibitor.id;
      if (this.productInfo.banner) {
        this.shareBanner = JSON.parse(this.productInfo.banner);
        this.productInfo.banner = JSON.parse(this.productInfo.banner);
      }
      //产品类别
      if (this.productInfo.categoryEn && this.productInfo.categoryEn.indexOf("[") !== -1) {
        let category = JSON.parse(data.data.productQuery.get.categoryEn);
        this.productInfo.categoryEn = this.getCate(category);
      }
      //展商类别
      if (this.productInfo.exhibitor.categoryEn && this.productInfo.exhibitor.categoryEn.indexOf("[") !== -1) {
        let category = JSON.parse(this.productInfo.exhibitor.categoryEn);
        this.productInfo.exhibitor.categoryEn = this.getCate(category);
      }
      //格式化详情信息
      if (this.productInfo.introduction.indexOf("[") !== -1) {
        // this.productInfo.introduction = eval(this.productInfo.introduction);
        this.introduction = JSON.parse(data.data.productQuery.get.introduction);

        this.introduction.forEach((el) => {
          if (el.picture&&el.picture.list&&el.picture.list.length) {
            this.pictureList.push(el.picture.list[0].url);
          }
        });
      }
      //格式化地区
      if (this.productInfo.exhibitor.company.regionEn) {
        let arr = this.productInfo.exhibitor.company.regionEn.split("/");
        this.productInfo.exhibitor.regionTxt = arr[arr.length - 2] + "·" + arr[arr.length - 1];
      } else {
        this.productInfo.exhibitor.regionTxt = "";
      }
      //视频
      if (this.productInfo.video && this.productInfo.video.indexOf("{") !== -1) {
        this.productInfo.video = JSON.parse(this.productInfo.video);
      }
      //产品标签
      if (this.productInfo.tag) {
        this.productInfo.tag = this.productInfo.tag.split(";");
      }
      console.log(this.productInfo, "data");
      document.title = this.productInfo ? `${this.productInfo.name + "-"}${this.exhibitionInfo.nameEn}` : "ExShow";
      this.setShare();
      this.getVisit();
      this.getData();
    },
    //获得访问信息
    async getVisit() {
      this.visitOpt.totalCount += 1;
      let query = `
      query queryProductUser(
        $page: Int!
        $size: Int!
        $where: String!
        $order: String
        ) {
            queryProductUser(
              query: { page: $page, size: $size, where: $where, order: $order }
              ) {
                hasNextPage
                  pageIndex
                totalCount
                items {
                    id
                    userInfo
                    }
            }
        }
        `;
      let where = {
        Equal: {
          n: "productId",
          v: this.productId,
        },
        Equal_isDeleted: {
          n: "isDeleted",
          v: 0,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          page: this.visitOpt.page,
          size: this.visitOpt.size,
          where: JSON.stringify(where),
          order: JSON.stringify(order),
        },
      };
      let data1 = await this.graphqlPost(opt);
      console.log(data1, "访问data");
      let res = JSON.parse(JSON.stringify(data1.data.queryProductUser));
      res.items.forEach((el) => {
        if (el.userInfo && el.userInfo.indexOf && el.userInfo.indexOf("{") !== -1) {
          el.userInfo = JSON.parse(el.userInfo);
        }
      });
      if (this.visitOpt.page == 1) {
        this.visitOpt.items = res.items;
      } else {
        this.visitOpt.items = [...new Set([...this.visitOpt.items, ...res.items])];
      }
      this.visitOpt.page = res.pageIndex;
      this.visitOpt.totalCount = res.totalCount;
      if (!res.hasNextPage) {
        this.visitOpt.finished = true;
      }
    },
    //获取热门产品
    async getData() {
      let query = `
                     query queryProduct(
                         $page: Int!
                         $size: Int!
                         $where: String!
                         $order: String
                        ) {
                         queryProduct(
                         query: { page: $page, size: $size, where: $where, order: $order }
                        ) {
                            hasNextPage
                            indexFrom
                            pageIndex
                            pageSize
                            totalCount
                        items {
                            id
                            name
                            selling
                            banner
                            view3DUrl
                            panoramaUrl
                            isPopularize
                            category
                            video
                         }
                        }
                     }
                    `;
      let where = {
        Equal: {
          n: "exhibitorId",
          v: this.exhibitorId,
        },
        Equal2: {
          n: "auditState", // 审核通过
          v: 1,
        },
        NotEqual: {
          n: "id",
          v: this.productId,
        },
      };
      let order = [
        {
          N: "showIndex",
          v: 0,
        },
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          page: this.pageIndex,
          size: this.pageSize,
          where: JSON.stringify(where),
          order: JSON.stringify(order),
        },
      };
      let data3 = await this.graphqlPost(opt);
      console.log(data3, "22222222222222222");
      let res = data3.data.queryProduct;
      // this.totalCount = data.data.totalCount;
      // let res = JSON.parse(JSON.stringify(data.data.queryProduct));
      // let res=data.data.queryProduct;

      if (res && res.items.length) {
        res.items.forEach((el) => {
          let arr = [];
          if (el.category) {
            el.category = JSON.parse(el.category);
            console.log(el.category, "el.category");
            arr.push(el.category.Value);
            if (el.category.lists) {
              el.category.lists.forEach((l) => {
                arr.push(l.Value);
              });
            }
          }

          // this.proCategory.push(Category)

          console.log(arr.join("/"), "proCategory");
          this.proCategory.push(arr.join("/"));
          if (el.banner && !Array.isArray(el.banner)) {
            el.banner = JSON.parse(el.banner);
          } else if (!Array.isArray(el.banner)) {
            el.banner = [];
          }
        });
      }
      // console.log(this.pageIndex,'2211233132111');
      if (this.pageIndex == 1) {
        this.List = res.items;
      } else {
        this.List = [...new Set([...this.List, ...res.items])];
      }
      if (this.List.banner) {
        this.List.banner = JSON.parse(this.List.banner);
      }
      console.log(this.List, "this.List");
    },
    goProduct(id) {
      this.linkToProductDetais(id);
      this.productId=id;
      this.productInfo=null
      this.init()
    },
    //类别转化
    getCate(arr) {
      console.log(arr, "---");
      let str = "";
      if (arr && arr[0]) {
        for (let i = 0; i < arr.length; i++) {
          str += str ? "," + arr[i].Value : arr[i].Value;
        }
        return str;
      } else {
        return "";
      }
    },
    onLineTalk () {
      if(!this.isRegOk){
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('You do not have access');
        return 
      }
      if(this.userInfo&&this.isServer&&this.userInfo.inMember.exhibitorId==this.exhibitorId){
        this.$Message.warning('Unable to chat with your company')
        return 
      }
      if (this.productInfo && this.exhibitorId) {
        this.addAppointmentNumber(this.exhibitorId);
      }
      this.talkShow=true;
      // this.$Message.warning('暂未开放')
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.play_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  i {
    font-size: 48px;
    color: #fff;
  }
}
.rebang {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 48px;
  height: 20px;
  @include background_color(#ff5e00);
  color: #fff;
  font-size: 12px;
}
.is_threeD {
  position: absolute;
  top: 0;
  right: 0px;
  width: 42px;
  height: 20px;
  background-color: #fff;
  border-radius: 0px 0px 0px 5px;
  border: solid 1px #ff5c00;
  display: flex;
  align-items: center;
}
.qj_div {
  position: absolute;
  right: 15px;
  bottom: 15px;
  background: rgba($color: #000, $alpha: 0.4);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  i {
    color: #fff;
    font-size: 24px;
  }
}
.product-describe {
  padding: 50px 65px 65px 75px;

  p {
    color: #333;
    line-height: 24px;
    margin-bottom: 20px;
  }

  img {
    width: 700px;
    margin-bottom: 30px;
  }
  .int-video {
    width: 700px;
  }
}
</style>
