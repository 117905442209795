<template>
  <div>
    <Modal v-model="show" width="640" :mask-closable="false" :footer-hide="true" @on-cancel="cancel" class-name="vertical-center-modal">
      <div slot="header" class="Modal-head">
        <div class="Modal-head-left"></div>
        <div class="Modal-head-right">Inquiry</div>
      </div>
      <div class="Modal-center">
        <div class="Modal-item" style="margin-bottom: 30px">
          <span>Quantity：</span>
          <Input v-model="amount" class="text_area" placeholder="Please input" style="width: 500px; padding: 0;" />
        </div>
        <div class="Modal-item" style="margin-bottom: 30px">
          <span>Message：</span>
          <Input v-model="con" type="textarea" class="text_area" :autosize="{ maxRows: 4, minRows: 4 }" :rows="4" placeholder="Please input" style="width: 500px; padding: 0;" />
        </div>
        <div style="margin: 0 auto; display: flex; justify-content: center">
          <Button style="width: 130px; height: 36px; margin-right: 20px" @click="cancel">Cancel</Button>
          <Button type="primary" style="width: 130px; height: 36px" @click="submit">Submit</Button>
        </div>
      </div>
    </Modal>
    <!-- <Modal
      v-model="hintShow"
      width="640"
      :footer-hide="true"
      :mask-closable="false"
      @on-cancel="hintShow = false"
      class-name="vertical-center-modal"
    >
      <p>您的预约洽谈信息已成功发送</p>
      <p>后续动态请在个人中心预约中查询</p>
      <div style="margin: 0 auto; display: flex; justify-content: center">
        <Button
          style="width: 130px; height: 36px; margin-right: 20px"
          @click="hintShow = false"
          >Cancel</Button
        >
        <Button
          type="primary"
          style="width: 130px; height: 36px"
          @click="goToLook"
          >去看看</Button
        >
      </div>
    </Modal> -->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "inquiryModal",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeType: "",
      show: false,
      con: "",
      amount: "",
      info: {},
      hintShow: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      addEnquiryNew: "exhibitor/addEnquiryNew",
      editOrder: "user/editOrder",
      editEnquiryNew: "user/editEnquiryNew",
      getUserAdvisorySetting:"getUserAdvisorySetting"
    }),
    open(info = null) {
      this.info = info;
      if (this.isEdit) {
        this.amount = info.amount;
        this.con = info.remark;
      } 
      this.show = true;
    },
    async submit() {
      if (!this.info.exhibitorId && !this.isEdit) {
        this.$Message.warning("Wrong exhibitor information！");
        return;
      }
      if (!this.amount) {
        this.$Message.warning("Please Input！");
        return;
      }
      if (!this.con) {
        this.$Message.warning("Please Input！");
        return;
      }
      if (this.isEdit) {
        let opt = {
          id: this.info.id,
          opt: {
            remark: this.con,
            amount: this.amount,
            auditState: 0,
          },
        };
        let result = await this.editEnquiryNew(opt);
        if (result.result) {
          this.$Message.success("Saved successfully");
          this.$emit("finish");
          this.cancel();
        } else {
          this.$Message.error(result.message || "The modification failed");
        }
        //编辑模式
      } else {
        // 新增模式
        let body = {
          exhibitionId: this.exhibitionId,
          exhibitorId: this.info.exhibitorId,
          productId: this.info.productId,
          userId: this.user.userId,
          remark: this.con,
          productName: this.info.productName,
          amount: this.amount,
          langVersion:'en'
        };
        let result = await this.addEnquiryNew(body);
        if (result.result) {
          this.show = false;
          this.$Message.success("success");
        } else {
          this.$Message.error(result.message=='Your number of inquiries has reached the limit   '?'':result.message || "fail");
        }
        // this.getUserAdvisorySetting();
      }
    },
    cancel() {
      this.show = false;
      this.con = "";
      this.amount = "";
      this.info = {};
    },
    goToLook() {
      this.$router.push({ path: "/user/order" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.Modal-head {
  display: flex;
  align-items: center;
  &-left {
    width: 4px;
    height: 18px;
    border: solid 3px #1890ff;
    @include border_color(#1890ff);
    margin-right: 10px;
  }
  &-right {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
}
.Modal-center {
  padding: 24px 18px;
}
.Modal-item {
  display: flex;
  align-items: center;
}
.btn {
  width: 92px;
  height: 36px;
  background-color: #f7f7f7;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  &:hover,
  &.activeNames {
    @include background_color(#1890ff);
    color: #fff;
  }
}
::v-deep {
  .ivu-modal-body {
    padding-left: 0;
    .text_area {
      textarea {
        resize: none;
      }
    }
    p {
      text-align: center;
      line-height: 24px;
      margin-bottom: 20px;
      &:first-child {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
  .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal {
      top: 0;
    }
  }
}
</style>
